import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import palette from 'theme/palette';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getConfiguration } from '../../../../config';
import auth0Client from '../../../../Auth'
import axios from 'axios';
axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {
    height: '350px'
  },
  chartContainer: {
    position: 'relative',
    height: '250px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  cardHeader:{
    fontFamily:'ProximanovaSemiBold',
    color:theme.palette.primary.cardHeader,
    fontSize:'18px'
  }
}));

const PNPA = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [PNPAData, setPNPAData] = useState();
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  
  let monthArray = ["blank","Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  
  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
    const fetchData = async () => {
      const result = await axios.get('/getSixMonthNpa_Pnpa');
      renderPNPAChart(result.data.response.pnpaData);
    };
    fetchData();
  }, []);
  const history = useHistory();
  

  const renderPNPAChart = (data) => {

    for(let i=0;i<data.length;i++){
      
      let splitDate = data[i].date.split('-');
      let getSplittedYear = splitDate[0];
      let getSplittedMonth = splitDate[1];
      if(getSplittedMonth.substring(0,1) == "0"){
        getSplittedMonth = getSplittedMonth.substring(1,2);
      }
      let getMonth = monthArray[getSplittedMonth];

      let loanIds = data[i].loanid;
      let monthAmount = 0;

      for(let j=0;j<loanIds.length;j++){
        monthAmount += loanIds[j].dueAmount;
      }
      monthAmount = currencyFormat(monthAmount);
   
      setCategoriesArray(categoriesArray => categoriesArray.concat(getMonth +" "+ getSplittedYear.substring(2,4)));   
      setSeriesData(seriesData => seriesData.concat({y:data[i].pnpaCount,"pnpaAmount":monthAmount}));

      if(i == data.length -1){
        setPNPAData(data);
        
      }

    }
}

function currencyFormat(num) {
  return '₹' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
       classes={{ title: classes.cardHeader }}
        action={
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
        }
        title="Potential Non Performing Assets"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
        {PNPAData && PNPAData.length > 0 &&
          <HighchartsReact highcharts={Highcharts}
          options={{
            chart: {
              type: 'column',
              height: 280
            },
            title: {
              text: ''
            },
            subtitle: {
              text: ''
            },
            credits: {
              enabled: false
            },
            xAxis: {
              type: 'category',
              categories: categoriesArray,
              labels: {
                enabled: true,
                rotation: 0,
                style: {
                  fontSize: '13px',
                  fontFamily: 'ProximanovaSemiBold'
                }
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              }
            },
            plotOptions: {
              series: {
                point:{
                  events:{
                    click:function(e){
                      history.push({
                        pathname: '/PNPADetailView',
                        state: { index: this.index, NPAData:  PNPAData}
                    });
                    }
                  }
                }
              }
            },
            legend: {
              enabled: true
            },
            tooltip: {
              pointFormat: '<b>Total PNPA: {point.y:1f}</b><br><b>Total Amount: {point.npaAmount:1f}</b>'
            },
            series: [
              {
                name: 'Potential Non Performing Assets',
                color: 'rgba(255,193,206,.5)',
                borderColor: 'red',
                borderWidth: 1,
                data: seriesData,
                dataLabels: {
                  enabled: false,
                  rotation: -90,
                  color: '#FFFFFF',
                  align: 'right',
                  format: '{point.y}',
                  y: 5,
                  style: {
                    fontSize: '10px',
                    fontFamily: 'ProximanovaSemiBold'
                  }
                }
              }
            ]
          }}
          height="100px"/>
        }
        </div>
        
      </CardContent>
    </Card>
  );
};

PNPA.propTypes = {
  className: PropTypes.string
};

export default PNPA;
