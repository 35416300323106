const dev = {
  Url: 'https://guictrmuc4.execute-api.ap-south-1.amazonaws.com/dev',
  domain: 'http://localhost:3000'
};

const qa = {
  Url: 'https://r28tu1n9nc.execute-api.ap-south-1.amazonaws.com/qa',
  domain: 'https://develop--bpost.netlify.app'
};

const production = {
  Url: 'https://guictrmuc4.execute-api.ap-south-1.amazonaws.com/dev',
  domain: 'https://www.bpost.cognitochain.io'
};

export function getConfiguration() {
  if (process.env.REACT_APP_STAGE === 'qa') {
    return qa;
  }
  if (process.env.NODE_ENV === 'production') {
    return production;
  }
  return dev;
}
