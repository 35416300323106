import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import auth0Client from './Auth';
import Loader from 'react-loader-spinner';
class Callback extends Component {
  async componentDidMount() {
    await auth0Client.handleAuthentication();
    this.props.history.replace('/dashboard');
  }
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Loader type="ThreeDots" color="#00BFFF" height={400} width={100} />
      </div>
    );
  }
}

export default withRouter(Callback);
