import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { SearchInput } from 'components';
import startCase from 'lodash/startCase';
import auth0Client from '../../../../Auth';
import Loader from 'react-loader-spinner';
import { Paper, Typography } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Grid
} from '@material-ui/core';
import mockData from './data';
import { StatusBullet } from 'components';
import { Total, Paid, Due } from '../../components';
import uuid from 'uuid/v1';
import axios from 'axios';
import { getConfiguration } from '../../../../config';
import ItemCount from '../ItemCount';

axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  viewDetails: {
    color: '#517DB0',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  user: {
    width: '100%',
    border: '15px',
    padding: '50px',
    paddingLeft: '0',
    textAlign: 'center',
    paddingRight: '0'
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  toolbarContainer: {
    display: 'flex',
    margin: '1rem 0'
  }
}));

const paymentStatusColors = {
  Paid: 'success',
  'Partial Payment': 'info',
  Due: 'danger',
  'Advance Payment': 'success'
};

const LoanDetails = props => {
  const history = useHistory();
  const goToUsersPage = event => {
    history.push('/users');
  };
  const { className, style, ...rest } = props;
  const classes = useStyles();
  const [startKeyIndex, setStartKeyIndex] = useState(0);
  const [orders, setData] = useState(mockData);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [totalMembers, setTotalMembers] = useState(10);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  var [startKeys, setStartKeys] = useState(['']);
  const [startKey, setStartKey] = useState('none');
  const [selectedValue, setSelectedValue] = React.useState('byname');
  const [time, setTime] = useState('none');
  var [isSearch, setIsSearch] = useState(false);
  var [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [dueAmount, setDeuAmount] = useState(0);
  const [partialPaidTotal, setPartialPaidTotal] = useState(0);
  const [advancePaidTotal, setAdvancePaidTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [searching, setSearching] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handlePageChange = (event, page) => {
    if (page - startKeyIndex === -1) {
      setStartKeyIndex(startKeyIndex - 1);
      handleBackWardData();
      setPage(page);
    } else {
      handleForwardData();
      setStartKeyIndex(startKeyIndex + 1);
      setPage(page);
    }
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    limit = event.target.value;
    fetchData();
    setLimit(event.target.value);
  };

  const searchByLoanId = async searchValue => {
    var orders = [];
    var result;
    try {
      setSearching(true);
      result = await axios.get('/loan-contract-info?loanID=' + searchValue);
      let order = {
        loanID: result.data.loanID,
        repaymentStatus: result.data.status,
        balance: result.data.balance,
        dueDate: moment(result.data.dueDate).format('Do MMM YYYY')
      };
      if (result.data && result.data.memberUUID) {
        const UserDetailsresult = await axios.get(
          '/members-info?memberUUID=' + result.data.memberUUID
        );
        order.memberName =
          UserDetailsresult.data.memberName +
          ' ' +
          UserDetailsresult.data.memberSurname;
        orders = orders.concat(order);
      }
    } catch (error) {}
    setSearching(false);
    setData(orders);
  };
  const fetchData = async () => {
    const result = await axios.get('/loan-contract-info' + '?limit=' + limit);
    setTotalMembers(result.data.totalMembers);
    const arrMember = result.data.arrLoans;
    await Promise.all(
      arrMember.map(loanItem => {
        loanItem.id = uuid();
        loanItem.memberName = startCase(loanItem.memberName);
        loanItem.dueDate = moment(loanItem.dueDate).format('Do MMM YYYY');
      })
    );
    setData(arrMember);
    setdataLoaded(true);
    setStartKeys(startKeys.concat(result.data.startKey));
    setSearching(false);
    setLoading(false);
  };

  const loanDetails = async () => {
    const result = await axios.get('/loan-info');
    setAdvancePaidTotal(result.data.advancePaidTotal);
    setDeuAmount(result.data.dueTotal);
    setItemCount(result.data.itemCount);
    setPaidTotal(result.data.paidTotal);
    setPartialPaidTotal(result.data.partialPaidTotal);
    setTotal(result.data.total);
  };

  const handleBackWardData = async () => {
    const result = await axios.get(
      '/loan-contract-info' +
        '?limit=' +
        limit +
        '&startKey=' +
        startKeys[page - 1]
    );
    setTotalMembers(result.data.totalMembers);
    const arrMember = result.data.arrLoans;
    await Promise.all(
      arrMember.map(loanItem => {
        loanItem.id = uuid();
        loanItem.memberName = startCase(loanItem.memberName);
        loanItem.dueDate = moment(loanItem.dueDate).format('Do MMM YYYY');
      })
    );
    setData(arrMember);
    setdataLoaded(true);
  };

  const handleForwardData = async () => {
    const result = await axios.get(
      '/loan-contract-info' +
        '?limit=' +
        limit +
        '&startKey=' +
        startKeys[page + 1]
    );
    setTotalMembers(result.data.totalMembers);
    const arrMember = result.data.arrLoans;
    await Promise.all(
      arrMember.map(loanItem => {
        loanItem.id = uuid();
        loanItem.memberName = startCase(loanItem.memberName);
        loanItem.dueDate = moment(loanItem.dueDate).format('Do MMM YYYY');
      })
    );
    setData(arrMember);
    setdataLoaded(true);
    startKeys[page + 2] = result.data.startKey;
  };

  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
    if (page === 0 || page === 1) {
      loanDetails();
      fetchData();
    } else if (page - startKeyIndex === -1) handleBackWardData();
    else handleForwardData();
  }, []);

  const goToMemberLoanDetailsPage = event => {
    history.push('/memberLoansDetails/' + event);
  };

  return isLoading ? (
    <Loader
      className={classes.loader}
      type="ThreeDots"
      color="#00BFFF"
      height={400}
      width={100}
    />
  ) : (
    auth0Client.isAuthenticated() && dataLoaded && (
      <div style={{ padding: '24px' }}>
        <Grid container spacing={5}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ItemCount itemCount={itemCount}></ItemCount>
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Paid amount={paidTotal + advancePaidTotal}></Paid>
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Due amount={dueAmount + partialPaidTotal}></Due>
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Total total={total}></Total>
          </Grid>
        </Grid>

        <Typography variant="h3" className={classes.PageTitle}></Typography>
        <div {...rest} className={clsx(classes.root, className)}>
          <div className={classes.toolbarContainer}>
            <div className={classes.row} style={{ flex: '1' }}>
              <SearchInput
                className={classes.searchInput}
                placeholder="Enter Loan ID"
                onChange={event => {
                  if (event.target.value.length === 0) {
                    setIsSearch(false);
                    clearTimeout(time);
                    fetchData();
                  }
                  if (event.target.value.length > 4) {
                    clearTimeout(time);
                    let name = event.target.value;
                    let timeout = setTimeout(() => {
                      searchByLoanId(name);
                    }, 1000);
                    setTime(timeout);
                    setIsSearch(true);
                  }
                }}
              />
            </div>
            <div
              className={clsx(classes.main1, className)}
              style={{ flex: '1' }}></div>
          </div>
        </div>
        {
          <Paper
            {...rest}
            className={clsx(classes.main, className)}
            style={style}>
            <div className={classes.row}></div>
          </Paper>
        }

        <Card
          {...rest}
          className={clsx(classes.root, className)}
          style={{ marginBottom: '5%' }}>
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Loan ID</TableCell>
                      <TableCell>Member Name</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>Repayment Status</TableCell>
                      <TableCell>Due Amout</TableCell>
                      <TableCell>View Details</TableCell>
                    </TableRow>
                  </TableHead>
                  {searching ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan="6">
                          <Loader
                            className={classes.loader}
                            type="ThreeDots"
                            color="#00BFFF"
                            height={400}
                            width={100}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {orders.length !== 0 &&
                        orders.map(order => (
                          <TableRow hover key={order.id}>
                            <TableCell> {order.loanID} </TableCell>
                            <TableCell>{order.memberName}</TableCell>
                            <TableCell>{order.dueDate}</TableCell>
                            <TableCell>
                              <div className={classes.statusContainer}>
                                <StatusBullet
                                  className={classes.status}
                                  color={
                                    paymentStatusColors[order.repaymentStatus]
                                  }
                                  size="sm"
                                />
                                {order.repaymentStatus}
                              </div>
                            </TableCell>
                            <TableCell>{order.balance}</TableCell>
                            <TableCell
                              className={classes.viewDetails}
                              onClick={() => {
                                goToMemberLoanDetailsPage(order.loanID);
                              }}>
                              View Details
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                  {orders.length == 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan="6">
                          <div className={classes.user}>
                            <h3>No LoanId Found</h3>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={totalMembers}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
          <Divider />
        </Card>
      </div>
    )
  );
};

LoanDetails.propTypes = {
  className: PropTypes.string
};

export default LoanDetails;
