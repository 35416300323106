import React from 'react';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { TextInput } from 'components';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import auth0Client from '../../../../Auth';

const useStyles = makeStyles(theme => ({
  FPassword: {
    float: 'right'
  },
  signIn: {
    marginTop: '10px',
    '& input': {
      color: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      }
    }
  },
  formheading: {
    color: 'white',
    paddingBottom: '20px'
  },
  container: {
    marginTop: '10px',
    width: '85%',
    margin: 'auto',
    '& input[type="password"]': {
      backgroundColor: '#e6e4e4',
      color: 'black'
    },
    '& input[type="text"]': {
      backgroundColor: '#e6e4e4',
      color: 'black'
    }
  },
  Loginbutton: {
    margin: '18px 0px',
    '& button': {
      width: '100%',
      backgroundColor: '#3f51b5',
      '&.MuiButton-contained.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26);',
        backgroundColor: '#3f51b5'
      }
    }
  },
  BPost: {
    marginTop: '50px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '20px'
  },
  topIcons: {
    display: 'flex'
  },
  rememberMe: {
    '&.MuiCheckbox-root': {
      padding: '0',
      marginTop: '-2px'
    },
    '&.MuiCheckbox-root .MuiSvgIcon-root': {
      height: '20px',
      width: '20px'
    }
  }
}));

const Login = () => {
  const classes = useStyles();
  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required')
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      auth0Client.signIn(values.email, values.password);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.signIn}>
        <div className={classes.topIcons}>
          <div>
            <img
              alt="Logo"
              src="/images/logos/govt-logo_innerpage.png"
              height="50"
            />
          </div>
          <div className={classes.icon}>
            <img
              alt="Logo"
              src="/images/logos/serp-logo_innerpage.png"
              height="50"
            />
            <img alt="Logo" src="/images/logos/mepma.png" height="50" />
          </div>
        </div>
        <div className={classes.BPost}>
          <img alt="Logo" src="/images/logos/B-Post.png" height="50" />
        </div>
        <div className={classes.container}>
          <label for="email">User Name</label>
          <br />
          <TextInput
            inputType="text"
            name="email"
            variant="outlined"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <br />
          <br />
          <label for="email">Password</label>
          <br />
          <TextInput
            inputType="password"
            name="password"
            variant="outlined"
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <br />
          <br />
          <div className={classes.Loginbutton}>
            <Button variant="contained" color="primary" type="submit">
              Log In
            </Button>
          </div>
          <div>
            <Checkbox className={classes.rememberMe} color="primary" />
            <span style={{ marginLeft: '5px' }}>Remember Me</span>
            <span className={classes.FPassword}>
              <RouterLink to="/forget-password">Forget Password?</RouterLink>
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
