import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  eye: {
    cursor: 'pointer'
  }
}));
const TextInput = props => {
  const classes = useStyles();
  const [showPassword, toggleShowPassword] = useState(true);
  const handleClickShowPassword = () => toggleShowPassword(!showPassword);
  const handleMouseDownPassword = event => event.preventDefault();
  return (
    <TextField
      type={
        showPassword && props.inputType === 'password' ? 'password' : 'text'
      }
      {...props}
      InputProps={
        props.inputType === 'password'
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.eye}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          : undefined
      }
    />
  );
};

export default TextInput;
