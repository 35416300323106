import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Home as HomeLayout
} from './layouts';
import {
  Home as HomeView,
  Dashboard as DashboardView,
  UserList as UserListView,
  LoanDetails as LoanDetailsView,
  NotFound as NotFoundView,
  MemberDetails as MemberDetailsView,
  MemberLoansDetails,
  ForgetPassword as ForgetPasswordView,
  NPADetailView as NPADetailView,
  PNPADetailView as PNPADetailView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={HomeLayout}
        path="/"
      />
      <RouteWithLayout
        component={ForgetPasswordView}
        exact
        layout={HomeLayout}
        path="/forget-password"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={NPADetailView}
        exact
        layout={MainLayout}
        path="/NPADetailView"
      />
      <RouteWithLayout
        component={PNPADetailView}
        exact
        layout={MainLayout}
        path="/PNPADetailView"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={LoanDetailsView}
        exact
        layout={MainLayout}
        path="/loanDetails"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={MemberDetailsView}
        exact
        layout={MainLayout}
        path="/memberDetails/:id"
      />
      <RouteWithLayout
        component={MemberLoansDetails}
        exact
        layout={MainLayout}
        path="/memberLoansDetails/:id"
      />
      {/* <Redirect to="/not-found" /> */}
    </Switch>
  );
};

export default Routes;
