import React from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import palette from 'theme/palette';
import NumberFormat from 'react-number-format';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import DemandIcon from '@material-ui/icons/Schedule';
import CollectionIcon from '@material-ui/icons/CheckCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import BalanceIcon from '@material-ui/icons/AccountBalanceWallet';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const useStyles = makeStyles(theme => ({
  root: {
    height: '350px'
  },
  chartContainer: {
    position: 'relative',
    height: '250px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  cardHeader:{
      fontFamily:'ProximanovaSemiBold',
      color:theme.palette.primary.cardHeader,
      fontSize:'18px'
    }
}));

const DCBPie = props => {
  const { className, demand, collection, balance, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const demandPerc = Math.round(demand[0]*100/(demand[0]+collection[0]+balance[0]))
  const collectionPerc = Math.round(collection[0]*100/demand[0])
  const balancePerc = demandPerc - collectionPerc

  
  const options = {
    
    chart: {
        type: 'line',
        height:280
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits:{
      enabled:false
    },
    xAxis: {
        type: 'category',
        categories: ['Mulugu','Sangareddy','Mancherial','Jogulamba','Adilabad','Nalgonda','Narayanpet','Yadadri  Bhongir','Siddipet','Nirmal','Peddapalli', 'Parvathagiri'],
        labels: {
            enabled:true,
            rotation: -45,
            style: {
                fontSize: '13px',
                fontFamily: 'ProximanovaSemiBold'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Amount (₹)'
        }
    },
    plotOptions:{
      column: {
            // pointWidth:10,
            pointPadding:.1,
            borderWidth: 0
        }
    },
    legend: {
        enabled: true
    },
    tooltip: {
        pointFormat: '<b>{point.y}</b>',
        formatter: function() {
          return '₹'+ Highcharts.numberFormat(this.y, 0);
      },
    },
    series: [{
        name: 'Credit Limit',
        color: '#BB6E37',
        data: [1296000,2640000,1760000,1760000,5100000,2400000,3120000,4920000,7200000,6000000,7200000, 2400000],
        dataLabels: {
            enabled: false,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y}',
            y: 5,
            style: {
                fontSize: '10px',
                fontFamily: 'ProximanovaSemiBold'
            }
        }
    },{
        name: 'Credit Plan',
        data: [597541,735781,563386,697279,3691723,1089554,2268815,2010793,1910399,1941210,2688089,91286],
        color: '#70A0D6',
        dataLabels: {
            enabled: false,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}',
            y: 10,
            style: {
                fontSize: '13px',
                fontFamily: 'ProximanovaSemiBold'
            }
        }
    },
    {
        name: 'Credit Performance',
        data: [0,863100,0,0,3073250,0,483500,0,2947675,1622125,0,2765094],
        color: "#77B079",
        dataLabels: {
            enabled: false,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}',
            y: 10,
            style: {
                fontSize: '13px',
                fontFamily: 'ProximanovaSemiBold'
            }
        }
    }]    
  };

  const devices = [
    {
      title: 'Demand',
      value: demandPerc,
      icon: <DemandIcon />,
      color: theme.palette.primary.main
    },
    {
      title: 'Balance',
      value: balancePerc,
      icon: <BalanceIcon />,
      color: theme.palette.error.main
    },
    {
      title: 'Collection',
      value: collectionPerc,
      icon: <CollectionIcon />,
      color: theme.palette.success.main
    }
  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader
      classes={{ title: classes.cardHeader }} 
        action={ <Button size="small" variant="text" > Group by District</Button>}
        title="VO Performance Plan"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>

          <HighchartsReact highcharts={Highcharts} options={options} height="100px"/>
        </div>
        
      </CardContent>
    </Card>
  );
};

DCBPie.propTypes = {
  className: PropTypes.string
};

export default DCBPie;
