import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import auth0Client from '../../../../Auth';
import { Button,Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import axios from 'axios';
import { getConfiguration } from '../../../../config';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MultiSelect from "react-multi-select-component";
import {
  Grid
} from '@material-ui/core';

axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  PageTitle:{
    paddingBottom:'10px'
  },
  backButton:{
    marginBottom:'20px'
  },
  detailsTable:{
    zIndex:'-1',
    marginBottom:'50px'
  },
  dropDownSection:{
    marginBottom:'20px'
  },
  PageTitle: {
    color: theme.palette.primary.PageTitle,
    fontFamily: 'ProximanovaSemiBold'
  },
  gd: {
    marginBottom: '-35px'
  },
  stepper: {
    marginTop: '10px',
    marginBottom: '20px'
  },
  previousName: {
    color: '#517DB0',
    cursor: 'pointer',
    fontFamily: 'ProximanovaSemiBold'
  }
  
}));

const paymentStatusColors = {
  Paid: 'success',
  'Partial Payment': 'info',
  Due: 'danger',
  'Advance Payment': 'success'
};

const PNPADetailView = props => {
  const location = useLocation();
  const history = useHistory();
  const [loanData, setLoanData] = useState([]);
  const [initialLoanData, setInitialLoanData] = useState([]);
  
  const { className, style, ...rest } = props;
  const classes = useStyles();

  const [searchByMandalOptions, setSearchByMandalOptions] = useState([]);
  const [searchByVoNameOptions, setSearchByVoNameOptions] = useState([]);
  const [searchByMonthsOptions, setSearchByMonthsOptions] = useState([]);

  const [selectedMandalName, setSelectedMandalName] = useState([]);
  const [selectedVoName, setSelectedVoName] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
 

  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
    showLoanIdTable();
  }, []);

  const showLoanIdTable = () =>{
    let mandalNameArray = [];
    let voNameArray = [];
    let monthsArray = [];
    
    let data = location.state.NPAData;
    
    for(let i=0;i<data.length;i++){
      
      let loanIds = data[i].loanid;
      
      for(let j=0;j<loanIds.length;j++){

        if(data[i].date == location.state.NPAData[location.state.index].date){
          setLoanData(loanData => loanData.concat(
            {
              "loanId":loanIds[j].loanId,
              "mandalName":loanIds[j].mandalName,
              "memberName":loanIds[j].memberName,
              "dueAmount":currencyFormat(loanIds[j].dueAmount),
              "dueDate":loanIds[j].dueDate,
              "voName":loanIds[j].voName
            }        
          ));
          setInitialLoanData(initialLoanData => initialLoanData.concat(
            {
              "loanId":loanIds[j].loanId,
              "mandalName":loanIds[j].mandalName,
              "memberName":loanIds[j].memberName,
              "dueAmount":currencyFormat(loanIds[j].dueAmount),
              "dueDate":loanIds[j].dueDate,
              "voName":loanIds[j].voName
            }
          ));
        }
        
          mandalNameArray.push(loanIds[j].mandalName);
          voNameArray.push(loanIds[j].voName);
          
      }
      monthsArray.push(data[i].date);

      if(i == data.length-1){
        setSelectedMonth(selectedMonth => selectedMonth.concat({"label":data[location.state.index].date,"value":data[location.state.index].date}));
        mandalNameArray = Array.from(new Set(mandalNameArray));
        createDropDownByMandalName(mandalNameArray);
        
        voNameArray = Array.from(new Set(voNameArray));
        createDropDownByVoName(voNameArray);
        
        monthsArray = Array.from(new Set(monthsArray));
        createDropDownByMonthsArray(monthsArray);
      }
      
    }

  }

  const createDropDownByMandalName = (mandalNameArray) => {
    for(let i=0;i<mandalNameArray.length;i++){
      setSearchByMandalOptions(searchByMandalOptions => searchByMandalOptions.concat(
        {
          "label":mandalNameArray[i],
          "value":mandalNameArray[i]
        }
      ));
    }
    
  }

  const createDropDownByVoName = (VoNameArray) => {
    setSearchByVoNameOptions([]);
    for(let i=0;i<VoNameArray.length;i++){
      setSearchByVoNameOptions(searchByVoNameOptions => searchByVoNameOptions.concat(
        {
          "label":VoNameArray[i],
          "value":VoNameArray[i]
        }
      ));
    }
    
  }

  const createDropDownByMonthsArray = (monthsArray) => {
    for(let i=0;i<monthsArray.length;i++){
      setSearchByMonthsOptions(searchByMonthsOptions => searchByMonthsOptions.concat(
        {
          "label":monthsArray[i],
          "value":monthsArray[i]
        }
      ));
    }
    
  }

  const columns = [
    {
      title:'Loan Id', field:'loanId'
    },
    {
      title:'Mandal Name', field:'mandalName'
    },
    {
      title:'VO Name', field:'voName'
    },
    {
      title:'Member Name', field:'memberName'
    },
    {
      title:'Due Date', field:'dueDate'
    },
    {
      title:'Due Amount', field:'dueAmount'
    }
    
  ];

  const backToDashboard = event => {
    history.push('/dashboard');
  };

  
  const filterByMandalName = (e) => {
    setSelectedMandalName(e);
    
    let voName = [];
    let Ldata = [];
    
    if(e.length == 0){
      setSelectedVoName([]);
      for(let i=0;i<initialLoanData.length;i++){
        voName.push(initialLoanData[i].voName);
        Ldata.push({
          "loanId":initialLoanData[i].loanId,
          "mandalName":initialLoanData[i].mandalName,
          "memberName":initialLoanData[i].memberName,
          "dueAmount":initialLoanData[i].dueAmount,
          "dueDate":initialLoanData[i].dueDate,
          "voName":initialLoanData[i].voName
        })
        if(i == initialLoanData.length -1){
          voName = Array.from(new Set(voName));
          createDropDownByVoName(voName);
          reRenderLoanTable(Ldata);
        }
  
      }
    }
    
    for(let i=0;i<initialLoanData.length;i++){

      for(let j=0;j<e.length;j++){
        
        if(e[j].value == initialLoanData[i].mandalName){
          voName.push(initialLoanData[i].voName);
            Ldata.push({
              "loanId":initialLoanData[i].loanId,
              "mandalName":initialLoanData[i].mandalName,
              "memberName":initialLoanData[i].memberName,
              "dueAmount":initialLoanData[i].dueAmount,
              "dueDate":initialLoanData[i].dueDate,
              "voName":initialLoanData[i].voName
            })
        }

      }

      if(i == initialLoanData.length -1){
        voName = Array.from(new Set(voName));
        createDropDownByVoName(voName);
        reRenderLoanTable(Ldata);
      }

    }
    
    
  };

  
  const filterByVoName = (e) => {
    setSelectedVoName(e);

    if(e.length == 0 && selectedMandalName.length == 0){
      setLoanData(initialLoanData);
    }else if(e.length == 0){
      filterByMandalName(selectedMandalName);
    }else{
      let Ldata = [];
    
      for(let i=0;i<initialLoanData.length;i++){
      
        for(let j=0;j<e.length;j++){
          
          if(e[j].value == initialLoanData[i].voName){
            
              Ldata.push({
                "loanId":initialLoanData[i].loanId,
                "mandalName":initialLoanData[i].mandalName,
                "memberName":initialLoanData[i].memberName,
                "dueAmount":initialLoanData[i].dueAmount,
                "dueDate":initialLoanData[i].dueDate,
                "voName":initialLoanData[i].voName
              })
          }

        }

        if(i == initialLoanData.length -1){
          reRenderLoanTable(Ldata);
        }

      }
    }
    
  };

  
  const filterByMonths = (e) => {
    setSelectedMonth(e);
    
    if(e.length == 0){
    
    }else{ 
      let Ldata = [];
      let allLoanData = location.state.NPAData;
      for(let i=0;i<allLoanData.length;i++){
        for(let j=0;j<e.length;j++){
          if(allLoanData[i].date.indexOf(e[j].value) > -1){
            for(let k=0;k<allLoanData[i].loanid.length;k++){
              Ldata.push({
                "loanId":allLoanData[i].loanid[k].loanId,
                "mandalName":allLoanData[i].loanid[k].mandalName,
                "memberName":allLoanData[i].loanid[k].memberName,
                "dueAmount":allLoanData[i].loanid[k].dueAmount,
                "dueDate":allLoanData[i].loanid[k].dueDate,
                "voName":allLoanData[i].loanid[k].voName
              })
            }
            

          }
        }

        if(i == allLoanData.length -1){
          if(selectedMandalName.length >0 && selectedVoName.length >0){
            reRenderLoanTable(Ldata);
            filterByVoName(selectedVoName);
          }else{
            reRenderLoanTable(Ldata);
          }
        }
        
      }
      
    }
    
  };

  const reRenderLoanTable = (Ldata) => {
    setLoanData(Ldata);  
  }

  const mandalNameSelectBoxAction = {
    "selectSomeItems": "Search By Mandal Name",
    "allItemsAreSelected": "All items are selected.",
    "selectAll": "Select All",
    "search": "Search",
    "clearSearch": "Clear Search"
  };

  const voNameSelectBoxAction = {
    "selectSomeItems": "Search By Vo Name",
    "allItemsAreSelected": "All items are selected.",
    "selectAll": "Select All",
    "search": "Search",
    "clearSearch": "Clear Search"
  };

  const monthsSelectBoxAction = {
    "selectSomeItems": "Search By Months",
    "allItemsAreSelected": "All items are selected.",
    "selectAll": "Select All",
    "search": "Search",
    "clearSearch": "Clear Search"
  };

  function currencyFormat(num) {
    return '₹' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <div style={{ padding: '24px' }}>
      {/* <Button
        color="primary"
        className={classes.backButton}
        startIcon={<ArrowBackIosIcon />}
        onClick={backToDashboard}
      >
        Back To Dashboard
      </Button> */}
        <Typography variant="h3" className={classes.PageTitle}>
          PNPA Details
        </Typography>

        <Typography variant="h5" className={classes.stepper}>
          <span
            className={classes.previousName}
            onClick={() => {
              backToDashboard();
            }}>
            Dashboard {'>'}{' '}
          </span>
          <span className={classes.currentName}>PNPA Details</span>
        </Typography>

      
      <div className={classes.dropDownSection}>
        <Grid container spacing={5}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MultiSelect
            options={searchByMonthsOptions}
            value={selectedMonth}
            onChange={filterByMonths}
            labelledBy={"Search By Months"}
            selectAllLabel={"Select All"}
            overrideStrings={monthsSelectBoxAction}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MultiSelect
            options={searchByMandalOptions}
            value={selectedMandalName}
            onChange={filterByMandalName}
            labelledBy={"Search By Mandal Name"}
            selectAllLabel={"Select All"}
            overrideStrings={mandalNameSelectBoxAction}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MultiSelect
            options={searchByVoNameOptions}
            value={selectedVoName}
            onChange={filterByVoName}
            labelledBy={"Search By Vo Name"}
            selectAllLabel={"Select All"}
            overrideStrings={voNameSelectBoxAction}
            />
          </Grid>

          

        </Grid>
        </div>
      
    <div className={classes.detailsTable}>
      <MaterialTable
      className={classes.selectBoxContainer}
      title=""
      localization={{
        toolbar: {
          
        }
      }}
      icons={{
        
      }}
      data={loanData}
      columns={columns}
      options={{
        rowStyle:{
          fontSize: 14,
        },
        actionsCellStyle:{
          width:'125px',
          color: '#517DB0',
          cursor: 'pointer',
          fontWeight: 'bold',
          hover:{
            background:'red'
          }
        },
        actionsColumnIndex:-1,
        search:false,
        paging:true,
        filtering:false,
        exportButton:true,
        exportFileName: 'title',
        headerStyle: {
          zIndex:'0'
        },
        exportButton: {
          csv: true,
          pdf: true
        }
      }}
      actions={[
        rowData => ({
          icon: () => 'View Details',
          iconProps: { style: { fontSize: "20px", color: "green" } },
          onClick: (event, rowData) => history.push('/memberLoansDetails/' + rowData.loanId)
        })
      ]}
      >

      </MaterialTable>
    </div>  
    </div>
        
  );
};

PNPADetailView.propTypes = {
  className: PropTypes.string
};

export default PNPADetailView;
