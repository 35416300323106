import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import axios from 'axios';
import { getConfiguration } from '../../../../config';
axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {},
  main: {
    borderRadius: '5px',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    marginBottom: '25px',
    display: 'flex',
    flexBasis: 420,
    height: '45px',
    width: '35%',
    display: 'inline-flex',
    lineHeight: '55px'
  },
  main1: {
    borderRadius: '4px',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    // marginBottom: '25px',
    display: 'flex',
    flexBasis: 420,
    marginTop: '0px',
    height: '55px',
    width: '50%',
    display: 'inline-flex',
    lineHeight: '55px',
    boxShadow: 'none',
    background: 'transparent'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: '55px',
    height: '55px'
  },
  icon1: {
    marginRight: theme.spacing(1),
    color: 'transparent',
    lineHeight: '55px',
    height: '55px'
  },
  input: {
    flexGrow: 1,
    fontSize: '18px',
    lineHeight: '10px',
    letterSpacing: '-0.05px'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: '995px'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableHeader: {
    paddingTop: '20px',
    fontSize: '16px',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.tableHeader
  },
  tableRow: {},
  tableBorder: {
    padding: '20px',
    fontSize: '16px',
    color: '#546e7a',
    textTransform: 'capitalize',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tableBorder1: {
    color: '#546e7a',
    fontSize: '16px'
  },
  user: {
    width: '100%',
    border: '15px',
    padding: '50px',
    paddingLeft: '0',
    textAlign: 'center',
    paddingRight: '0'
  },
  radioButton: {
    marginLeft: '0px'
  },
  checkboxLabel: {
    fontSize: 18,
    fontWeight: '500',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.tableHeader
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const UsersTable = props => {
  const history = useHistory();
  const { className, style, ...rest } = props;
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = React.useState('byname');

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dataLoaded, setdataLoaded] = useState(false);

  const [totalMembers, setTotalMembers] = useState(10);

  const [users, setData1] = useState([{ memberID: null }]);
  const [startKeyIndex, setStartKeyIndex] = useState(0);
  const [startKeys, setStartKeys] = useState(['none']);
  const [startKey, setStartKey] = useState('none');
  const [time, setTime] = useState('none');
  const [hidePagination, setHidePagination] = useState(true);
  var [limit, setLimit] = useState(10);
  var [isSearch, setIsSearch] = useState(false);
  var [searching, setSearching] = useState(false);
  const [user, setData] = useState([{ memberID: null }]);

  const fetchData = async () => {
    const result = await axios.get(
      '/members-info' + '?limit=' + limit + '&startKey=' + startKeys[page]
    );
    setData(result.data.members);
    setData1(result.data.members);
    setdataLoaded(true);
    setTotalMembers(result.data.totalMembers);
    setStartKeys(startKeys.concat(result.data.startKey));
    setSearching(false);
  };

  const handleBackWardData = async () => {
    const result = await axios.get(
      '/members-info' + '?limit=' + limit + '&startKey=' + startKeys[page - 1]
    );
    setData(result.data.members);
    setData1(result.data.members);
    setdataLoaded(true);
    setTotalMembers(result.data.totalMembers);
  };
  const handleForwardData = async () => {
    const result = await axios.get(
      '/members-info' + '?limit=' + limit + '&startKey=' + startKeys[page + 1]
    );
    setData(result.data.members);
    setData1(result.data.members);
    setdataLoaded(true);
    setTotalMembers(result.data.totalMembers);
    startKeys[page + 2] = result.data.startKey;
  };

  useEffect(() => {
    if (page === 0) {
      fetchData();
    } else if (page - startKeyIndex === -1) handleBackWardData();
    else handleForwardData();
  }, []);

  const goToProfilePage = event => {
    history.push('/memberDetails/' + event);
  };

  const handlePageChange = (event, page) => {
    if (isSearch) {
      setPage(page);
    } else {
      if (page - startKeyIndex === -1) {
        setStartKeyIndex(startKeyIndex - 1);
        handleBackWardData();
        setPage(page);
      } else {
        handleForwardData();
        setStartKeyIndex(startKeyIndex + 1);
        setPage(page);
      }
    }
  };

  const searchByName = async searchValue => {
    setSearching(true);
    setTotalMembers([]);
    const result = await axios.get('/members-info?name=' + searchValue);
    setData(result.data.members);
    setData1(result.data.members);
    setdataLoaded(true);
    setTotalMembers(result.data.totalMembers);
    setSearching(false);
  };

  const handleRowsPerPageChange = event => {
    if (isSearch) {
      setRowsPerPage(event.target.value);
    } else {
      setRowsPerPage(event.target.value);
      limit = event.target.value;
      fetchData();
      setLimit(event.target.value);
    }
  };

  return dataLoaded ? (
    <span>
      <Paper {...rest} className={clsx(classes.main, className)} style={style}>
        <SearchIcon className={classes.icon} />
        <Input
          {...rest}
          placeholder="Enter Members Name"
          className={classes.input}
          disableUnderline
          onChange={event => {
            if (event.target.value.length === 0) {
              clearTimeout(time);
              setIsSearch(false);
              fetchData();
            }

            if (event.target.value.length > 4) {
              clearTimeout(time);
              let name = event.target.value;
              let timeout = setTimeout(() => {
                searchByName(name);
              }, 1000);
              setTime(timeout);
              setIsSearch(true);
            }
          }}
        />
      </Paper>
      <Paper className={clsx(classes.main1, className)}>
        <SearchIcon className={classes.icon1} />
      </Paper>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      Member ID
                    </TableCell>
                    <TableCell className={classes.tableHeader}>Name</TableCell>
                    <TableCell className={classes.tableHeader}>
                      District
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Mandal
                    </TableCell>
                    <TableCell className={classes.tableHeader}>VO</TableCell>
                    <TableCell className={classes.tableHeader}>SHG</TableCell>
                  </TableRow>
                </TableHead>
                {searching ? (
                  <TableBody>
                    <TableCell colSpan="6">
                      <Loader
                        className={classes.loader}
                        type="ThreeDots"
                        color="#00BFFF"
                        height={400}
                        width={100}
                      />
                    </TableCell>
                  </TableBody>
                ) : (
                  <TableBody>
                    {user.length !== 0 &&
                      user
                        .slice(
                          isSearch ? page * rowsPerPage : 0,
                          isSearch
                            ? page * rowsPerPage + rowsPerPage
                            : rowsPerPage
                        )
                        .map((user, index) => (
                          <TableRow
                            key={index}
                            hover
                            onClick={() => {
                              goToProfilePage(user.memberUUID);
                            }}>
                            <TableCell className={classes.tableBorder}>
                              {user.memberID}
                            </TableCell>
                            <TableCell className={classes.tableBorder}>
                              <div className={classes.nameContainer}>
                                <Typography
                                  variant="body1"
                                  className={classes.tableBorder1}>
                                  {user.memberName}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              className={classes.tableBorder}
                              sortDirection="desc">
                              {user.districtName}
                            </TableCell>
                            <TableCell className={classes.tableBorder}>
                              {user.mandalName}
                            </TableCell>
                            <TableCell className={classes.tableBorder}>
                              {user.voName}
                            </TableCell>
                            <TableCell className={classes.tableBorder}>
                              {user.shgName}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                )}
                {user.length == 0 && (
                  <TableBody>
                    <TableCell colSpan="6">
                      <div className={classes.user}>
                        <h3>No Members Found</h3>
                      </div>
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalMembers}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </span>
  ) : (
    <Loader
      className={classes.loader}
      type="ThreeDots"
      color="#00BFFF"
      height={400}
      width={100}
    />
  );
};

UsersTable.propTypes = {
  className: PropTypes.string
};

export default UsersTable;
