import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import auth0Client from '../../Auth';
import ReactSpeedometer from 'react-d3-speedometer';

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

import axios from 'axios';
import { getConfiguration } from '../../config';
axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 300
  },
  firstClass: {
    padding: theme.spacing(3)
  },
  pageTitle: {
    color: theme.palette.primary.PageTitle,
    fontFamily: 'ProximanovaSemiBold'
  },
  backButton: {
    marginTop: '30px',
    marginBottom: '30px',
    fontSize: '16px'
  },
  Cardheader: {
    borderBottom: '1px solid rgba(0,0,0,.1)',
    paddingLeft: '21px',
    height: '57px',
    lineHeight: '57px',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.cardHeader,
    fontSize: '18px'
  },
  content: {
    padding: '0px'
  },
  name: {
    paddingBottom: '18px',
    paddingTop: '30px',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.tableHeader,
    fontSize: '16px'
  },
  value: {
    fontFamily: 'ProximanovaRegular',
    textTransform: 'capitalize',
    fontSize: '14px',
    color: theme.palette.primary.tableValue
  },
  grid: {
    paddingLeft: '21px'
  },
  grid1: {
    paddingLeft: '21px',
    paddingTop: '20px'
  },
  loanStatusDiv: {
    marginTop: '40px',
    minHeight: '300px'
  },
  viewDetails: {
    color: '#517DB0',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  stepper: {
    marginTop: '10px',
    marginBottom: '20px'
  },
  previousName: {
    color: '#517DB0',
    fontFamily: 'ProximanovaSemiBold'
  },
  tableHeader: {
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.tableHeader
  },
  tableValue: {
    fontFamily: 'ProximanovaRegular',
    color: theme.palette.primary.tableValue
  }
}));

const MemberDetails = props => {
  const theme = useTheme();
  const history = useHistory();
  const memberId = props.match.params.id;
  const classes = useStyles();
  const { className, staticContext, ...rest } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let [users, setData] = useState([{}]);
  let [usersL, setDataL] = useState([{}]);

  const goToUsersPage = event => {
    history.push('/users');
  };

  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
    const fetchData = async () => {
      const result = await axios.get('/members-info?memberUUID=' + memberId);
      setData(result.data);
      setDataL(result.data.loans);
    };
    fetchData();
  }, []);

  const goToMemberLoanDetailsPage = event => {
    history.push('/memberLoansDetails/' + event);
  };

  const data = {
    datasets: [
      {
        data: [1, 2, 3, 4, 5],

        backgroundColor: ['pink', 'orange', 'yellow', 'green', 'blue'],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: ['Poor', 'Fair', 'Good', 'Very Good', 'Exceptional']
  };

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false
    },
    title: {
      text: 'Credit Score',
      align: 'center',
      verticalAlign: 'middle',
      y: 20
    },
    tooltip: {
      pointFormat: '{series.name}'
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: 10,
          connectorColor: 'transparent',
          style: {
            color: 'red'
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '60%'],
        size: '120%'
      }
    },
    series: [
      {
        type: 'pie',
        name: ' Credit Score',
        innerSize: '98%',
        data: [
          { y: 1, name: 'Poor', color: 'pink' },
          { y: 1, name: 'Fair', color: 'orange' },
          { y: 1, name: 'Good', color: 'yellowgreen' },
          { y: 1, name: 'very Good', color: 'orange' },
          { y: 1, name: 'Excelent', color: 'green' }
        ]
      }
    ]
  };

  return (
    auth0Client.isAuthenticated() && (
      <div className={classes.firstClass}>
        <Typography variant="h3" className={classes.pageTitle}>
          Member Details
        </Typography>
        <Typography variant="h5" className={classes.stepper}>
          <span
            className={classes.previousName}
            onClick={() => {
              goToUsersPage();
            }}>
            Member Ledger {'>'}{' '}
          </span>
          <span className={classes.currentName}>Member Details</span>
        </Typography>

        <Grid container direction="row" spacing={4}>
          <Grid container item xs={12} md={6} lg={6}>
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardContent className={classes.content}>
                <div className={classes.Cardheader}>Profile</div>
                <Grid container justify="space-between">
                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={12}
                    className={classes.grid}>
                    <div className={classes.name}>Member ID</div>
                    <div className={classes.value}>{users.memberID}</div>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={12}
                    className={classes.grid}>
                    <div className={classes.name}>Name</div>
                    <div className={classes.value}>{users.memberName}</div>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={12}
                    className={classes.grid}>
                    <div className={classes.name}>District</div>
                    <div className={classes.value}>{users.districtName}</div>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={12}
                    className={classes.grid1}>
                    <div className={classes.name}>Mandal</div>
                    <div className={classes.value}>{users.mandalName}</div>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={12}
                    className={classes.grid1}>
                    <div className={classes.name}>VO</div>
                    <div className={classes.value}>{users.voName}</div>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={4}
                    xl={4}
                    xs={12}
                    className={classes.grid1}>
                    <div className={classes.name}>SHG</div>
                    <div className={classes.value}>{users.shgName}</div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Card {...rest} className={clsx(classes.root, className)}>
              <CardContent className={classes.content}>
                <div className={classes.Cardheader}>Credit Score</div>
                <Grid container alignItems="center" justify="center">
                  <ReactSpeedometer
                    value={users.creditScore}
                    ringWidth={5}
                    needleColor="#70A0D6"
                    needleTransitionDuration={2000}
                    needleTransition="easeCircleInOut"
                  />
                </Grid>
                {/* <HighchartsReact highcharts={Highcharts} options={options} height="100"/> */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Card {...rest} className={clsx(classes.loanStatusDiv, className)}>
          <CardContent className={classes.content}>
            <div className={classes.Cardheader}>Loans</div>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        Loan ID
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Disbursement Date
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Amount
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Status
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        SmartContract Address
                      </TableCell>
                      <TableCell className={classes.tableHeader}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersL.map(user => (
                      <TableRow hover key={user.loanID}>
                        <TableCell className={classes.tableValue}>
                          {user.loanID}
                        </TableCell>

                        <TableCell className={classes.tableValue}>
                          {user.disbursementDate}
                        </TableCell>

                        <TableCell className={classes.tableValue}>
                          {user.amount}
                        </TableCell>

                        <TableCell className={classes.tableValue}>
                          Active
                        </TableCell>

                        <TableCell className={classes.tableValue}>
                          {user.contractAddress}
                        </TableCell>

                        <TableCell
                          className={classes.viewDetails}
                          onClick={() => {
                            goToMemberLoanDetailsPage(user.loanID);
                          }}>
                          View Details
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </div>
    )
  );
};
MemberDetails.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string
};
export default MemberDetails;
