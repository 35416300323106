import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button
} from '@material-ui/core';
import moment from 'moment';

import axios from 'axios';
import { getConfiguration } from '../../../../config';
axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {
    height: '350px'
  },
  chartContainer: {
    height: 250,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cardHeader: {
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.cardHeader,
    fontSize: '18px'
  }
}));

const LatestSales = props => {
  const theme = useTheme();
  const { className, ...rest } = props;
  const classes = useStyles();
  const [data, setData] = useState({ demand: '', collection: '', balance: '' });

  var demands = [];
  var collections = [];
  var balance = [];

  useEffect(() => {
    const fetchData = async () => {
      //const result = await axios.post('/dcb?demandCycles=12');
      
      const result = {"data":{"demand":{"2020-03":50686067,"2020-04":66930409,"2020-05":83895846,"2020-06":96083985,"2020-07":109751009,"2020-08":124309271,"2020-09":138404237,"2020-10":154060028,"2020-11":174124123,"2020-12":204659243,"2021-01":241407052,"2021-02":11911465},"collection":{"2020-03":14431545,"2020-04":16127000,"2020-05":22169193,"2020-06":22084123,"2020-07":23048204,"2020-08":24755757,"2020-09":25200483,"2020-10":24514994,"2020-11":15316148,"2020-12":9129931,"2021-01":4715247,"2021-02":0},"balance":{"2020-03":36254522,"2020-04":50803409,"2020-05":61726653,"2020-06":73999862,"2020-07":86702805,"2020-08":99553514,"2020-09":113203754,"2020-10":129545034,"2020-11":158807975,"2020-12":195529312,"2021-01":236691805,"2021-02":11911465}}};

      var dates = [];
      const date = new Date();
      let currentYear = date.getFullYear();
      let currentMonth = getMonth(date);
      let ignoreMonth = currentYear+"-"+currentMonth;
      
      await Promise.all(
        Object.keys(result.data.demand).map(date => {
          if(date != ignoreMonth){
            dates.push(moment(date).format('MMM YY'));
            demands.push(result.data.demand[date]);
          }
        })
      );
      await Promise.all(
        Object.keys(result.data.collection).map(date => {
          if(date != ignoreMonth){
            collections.push(result.data.collection[date]);
          }
        })
      );
      await Promise.all(
        Object.keys(result.data.balance).map(date => {
          if(date != ignoreMonth){
            balance.push(result.data.balance[date]);
          }
        })
      );
      
      
      var map = {
        labels: dates,
        datasets: [
          {
            data: demands,
            fill: false,
            label: 'Demand',
            borderWidth: 2,
            borderColor: 'red'
          },
          {
            data: collections,
            fill: false,
            label: 'Demand1',
            borderCapStyle: 'butt',
            pointHitRadius: 10,
            borderWidth: 2,
            borderColor: 'green'
          },
          {
            data: balance,
            fill: false,
            label: 'Demand2',
            borderCapStyle: 'butt',
            pointHitRadius: 10,
            borderWidth: 2,
            borderColor: 'green'
          }
        ]
      };

      setData(map);
    };
    fetchData();
  }, []);
 
  function getMonth(date) {
    var month = date.getMonth() + 1;
    return month < 10 ? '0' + month : '' + month; 
  }  

  var demandArray = data.datasets ? data.datasets[0].data : [0];
  var collectionArray = data.datasets ? data.datasets[1].data : [0];
  var balanceArray = data.datasets ? data.datasets[2].data : [0];
  var rangeArray = data.labels ? data.labels : [];

  // demandArray.pop()
  // collectionArray.pop()
  const options = {
    chart: {
      type: 'line',
      height: 280
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      categories: rangeArray,
      labels: {
        enabled: true,
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'ProximanovaSemiBold'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Amount (₹)'
      }
    },
    plotOptions: {
      column: {
        // pointWidth:10,
        pointPadding: 0.1,
        borderWidth: 0
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      //pointFormat: '<b>{point.y}</b>'
    },
    series: [
      {
        name: 'Demand',
        color: '#70A0D6',
        // data: [10,12,5,7],
        data: demandArray,

        dataLabels: {
          enabled: false,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y}',
          y: 5,
          style: {
            fontSize: '10px',
            fontFamily: 'ProximanovaSemiBold'
          }
        }
      },
      {
        name: 'Collection',
        data: collectionArray,
        color: '#77B079 ',
        dataLabels: {
          enabled: false,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.1f}',
          y: 10,
          style: {
            fontSize: '13px',
            fontFamily: 'ProximanovaSemiBold'
          }
        }
      },
      {
        name: 'Balance',
        data: balanceArray,
        color: '#BB6E37 ',
        dataLabels: {
          enabled: false,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.1f}',
          y: 10,
          style: {
            fontSize: '13px',
            fontFamily: 'ProximanovaSemiBold'
          }
        }
      }
    ]
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        classes={{ title: classes.cardHeader }}
        action={
          <Button size="small" variant="text">
            {' '}
            Last 12 Months{' '}
          </Button>
        }
        title="DCB Report"
      />

      <Divider />

      <CardContent>
        <div className={classes.chartContainer}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            height="100px"
          />
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
