import React, { useState, useEffect }from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Hidden } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import auth0Client from '../../Auth';
import Icon from '@material-ui/core/Icon';

import { getConfiguration } from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '20px'
  },
  heading: {
    fontFamily:'ProximanovaSemiBold',
    color: 'white',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px'
    },
  },
  subheading:{
    fontFamily:'Proximanova',
    color: 'white',
    marginBottom: '25px'
  },
  minitsterName:{
    color: '#e51aa7'
  },
  textCenter:{
    textAlign: 'center',
    background:'#3f51b5',
    minHeight:'100vh'
  },
  launchButton: {
    background: "#e67e22",
    color: "white",
    borderRadius: "50px",
    border: "none",
    padding: "10px 50px",
    fontSize: "15px",
    '&:hover': {
      background: "#e67e22",
      color: "white"
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px'
    },
  },
  dashboardImage:{
    [theme.breakpoints.down('xs')]: {
      height: '100px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    },
    [theme.breakpoints.up('md')]: {
      height: '300px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '300px'
    },
  }
}));

const Home = () => {
  const classes = useStyles();
  return (
    <Container className={classes.textCenter}>
      <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid lg={2} sm={12} xl={3} xs={12}>
        <Hidden smDown>
          <Grid lg={2} sm={6} xl={3} xs={12}>
            <img src="/images/logos/rama-rao.png" height="180" />
          </Grid>  
        </Hidden>
        </Grid>
        <Grid lg={8} sm={12} xl={3} xs={12}>
        <div className={classes.paddingTop30} >
            <img alt="Logo" src="/images/logos/BPost-Logo.png" height="60" />
          </div>
          <Typography variant="h2" className={classes.subheading}>
          ( Blockchain - Protection for Streenidhi Transactions )
          </Typography> 
          <Button variant="contained" className={classes.launchButton} onClick={() => auth0Client.signIn()}>Launch</Button>
        </Grid>

        <Grid lg={2} sm={12} xl={3} xs={12}>
        <Hidden smDown>
          <Grid lg={2} sm={6} xl={3} xs={12}>
            <img src="/images/logos/Minister.png" height="180" />
          </Grid>  
        </Hidden>
        </Grid>
        
      </Grid>
      <div><img src="/images/dashboard.png" className={classes.dashboardImage} /></div>
    </div>
  </Container>
  );
};

export default Home;