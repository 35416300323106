import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {},
  centerImage: {
    textAlign: 'center',
    width: '100%'
  },
  centerImagee: {},
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  header: {
    backgroundImage: 'url(/images/header_bg.png)'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar className={clsx(classes.header, className)}>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logos/govt-logo_innerpage.png" />
        </RouterLink>
        <div className={clsx(classes.centerImage, className)}>
          <img
            className={clsx(classes.centerImagee, className)}
            height="60"
            alt="Logo"
            src="/images/logos/BPost-Logo.png"
          />{' '}
        </div>
        <Hidden mdDown>
          <img alt="Logo" src="/images/logos/serp-logo_innerpage.png" />
          <img alt="Logo" src="/images/logos/mepma.png" />
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
