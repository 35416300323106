import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersTable } from './components';
import { Typography } from '@material-ui/core';
import auth0Client from '../../Auth';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: '50px'
  },
  PageTitle: {
    color: theme.palette.primary.PageTitle,
    fontFamily: 'ProximanovaSemiBold'
  },
  main: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
    marginTop: '20px',
    width: '20%'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));
const UserList = props => {
  const classes = useStyles();
  const { className, style, ...rest } = '';
  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
  }, []);
  return (
    auth0Client.isAuthenticated() && (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.PageTitle}>
          Member Ledger
        </Typography>
        <div className={classes.content}>
          <UsersTable />
        </div>
      </div>
    )
  );
};

export default UserList;
