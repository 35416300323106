import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    marginTop: '-100px'
  }
}));

const Home = props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Home.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Home;
