import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ForgetPassword from './ForgetPassword';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0px'
  },
  main: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  subheading: {
    fontFamily: 'Proximanova',
    color: 'white',
    marginTop: '50px',
    textAlign: 'center'
  },
  subheading2: {
    fontFamily: 'Proximanova',
    color: 'white',
    marginTop: '30px',
    textAlign: 'center'
  },
  image_container: {
    marginTop: '60px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    '& img': {
      [theme.breakpoints.down('xs')]: {
        height: '100px'
      },
      [theme.breakpoints.down('sm')]: {
        height: '200px'
      },
      [theme.breakpoints.up('md')]: {
        height: '300px'
      },
      [theme.breakpoints.up('lg')]: {
        height: '300px'
      }
    }
  },
  heading: {
    fontFamily: 'ProximanovaSemiBold',
    color: 'white',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px'
    }
  },
  form_container: {
    height: '100vh',
    minHeight: '100%',
    minWidth: '350px',
    margin: '0px',
    padding: '2rem',
    color: 'black',
    background: 'white'
  },
  right_container: {
    backgroundImage: 'url(/images/background.jpg)'
  }
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Grid container>
          <Grid
            md={4}
            sm={12}
            direction="row"
            justify="center"
            alignItems="center">
            <div className={classes.form_container}>
              <ForgetPassword />
            </div>
          </Grid>
          <Grid className={classes.right_container} md={8} sm={12}>
            <div>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"></Box>
            </div>
            <br />
            <div className={classes.image_container}>
              <img src="/images/dashboard.png" alt="dashboard_logo" />
            </div>
            <Typography variant="h2" className={classes.subheading}>
              StreeNidhi Analytics Dashboard
            </Typography>
            <Typography variant="h5" className={classes.subheading2}>
              StreeNidhi Analytics Dashboard
              <br />
              StreeNidhi Analytics Dashboard
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Home;
