import auth0 from 'auth0-js';
import { getConfiguration } from './config';
const domain  = getConfiguration().domain;


class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: 'dev-cognitochain.eu.auth0.com',
      audience: 'https://dev-cognitochain.eu.auth0.com/userinfo',
      clientID: 'FeBRDKnc3EOn2A21yhDl0nPuaINyWCHr',
      redirectUri: `${domain}/callback`,
      responseType: 'id_token',
      scope: 'openid profile'
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    const tokenExpireTimeStamp = localStorage.getItem('expiresAtTimeStamp');
    return new Date().getTime() < tokenExpireTimeStamp;
  }

  signIn() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        localStorage.setItem('expiresAtTimeStamp', this.expiresAt);
        resolve();
      });
    })
  }
  signOut() {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    this.auth0.logout({
        returnTo: `${domain}`,
        clientID: 'FeBRDKnc3EOn2A21yhDl0nPuaINyWCHr',
    });
    localStorage.setItem('expiresAtTimeStamp', new Date().getTime());
  }
}

const auth0Client = new Auth();

export default auth0Client;