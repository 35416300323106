import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ReactSpeedometer from 'react-d3-speedometer';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import auth0Client from '../../Auth';
import axios from 'axios';
import { getConfiguration } from '../../config';
axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  userDetailsRoot: {
    width: '100%',
    height: 300
  },
  root: {
    width: '100%',
    height: 350
  },
  firstClass: {
    padding: theme.spacing(3)
  },
  backButton: {
    marginTop: '30px',
    marginBottom: '30px',
    fontSize: '16px'
  },
  Cardheader: {
    borderBottom: '1px solid rgba(0,0,0,.1)',
    paddingLeft: '21px',
    height: '57px',
    lineHeight: '57px',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.cardHeader,
    fontSize: '18px'
  },
  content: {
    padding: '0px'
  },
  name: {
    paddingBottom: '18px',
    paddingTop: '30px',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.name
  },
  userDetailsValue: {
    fontFamily: 'ProximanovaRegular',
    textTransform: 'capitalize',
    fontSize: '14px',
    color: theme.palette.primary.tableValue
  },
  value: {
    fontFamily: 'ProximanovaRegular',
    color: theme.palette.primary.tableValue,
    textTransform: 'capitalize'
  },
  grid: {
    paddingLeft: '21px'
  },
  grid1: {
    paddingLeft: '21px',
    paddingTop: '20px'
  },
  loanStatusDiv: {
    marginTop: '40px',
    minHeight: '300px',
    width: '100%'
  },
  marginTop20: {
    marginTop: '20px'
  },
  tableHeader: {
    textAlign: 'center',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.tableHeader
  },
  tableValue: {
    textAlign: 'center',
    fontFamily: 'ProximanovaRegular',
    color: theme.palette.primary.tableValue
  },
  PageTitle: {
    color: theme.palette.primary.PageTitle,
    fontFamily: 'ProximanovaSemiBold'
  },
  gd: {
    marginBottom: '-35px'
  },
  stepper: {
    marginTop: '10px',
    marginBottom: '20px'
  },
  previousName: {
    color: '#517DB0',
    cursor: 'pointer',
    fontFamily: 'ProximanovaSemiBold'
  }
}));

const MemberLoansDetails = props => {
  const theme = useTheme();
  const history = useHistory();
  const loanId = props.match.params.id;
  const classes = useStyles();
  const { className, staticContext, ...rest } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let [users, setData] = useState([{}]);
  let [usersLD, setDataLD] = useState([{}]);
  let [usersDL, setDataDL] = useState([{}]);
  let [userDetails, setDataUD] = useState([{}]);
  let [closingBalanceArray, closingBalanceArraySetData] = useState(0);
  let [principalArray, principalArraySetData] = useState(0);
  let [dateRangeArray, dateRangeArraySetData] = useState(0);

  let closingBalanceArray1 = [];
  let principalArray1 = [];
  let dateRangeArray1 = [];
  const goToUsersPage = event => {
    history.goBack('/memberDetails/');
  };

  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
    const fetchData = async () => {
      const result = await axios.get('/loan-contract-info?loanID=' + loanId);
      setDataLD(result.data.loanLedger);
      setDataDL(result.data.demandLedger);
      setData(result.data);
      // get member details
      if (result.data && result.data.memberUUID) {
        const UserDetailsresult = await axios.get(
          '/members-info?memberUUID=' + result.data.memberUUID
        );
        setDataUD(UserDetailsresult.data);
      }

      var amount = result.data.amount;
      for (var i = 0; i < result.data.loanLedger.length; i++) {
        dateRangeArray1.push(
          moment(result.data.loanLedger[i].date).format('MMM YY')
        );
        closingBalanceArray1.push(result.data.loanLedger[i].closingBalance);
        var principal = amount - result.data.loanLedger[i].closingBalance;
        principalArray1.push(principal);
      }

      closingBalanceArraySetData(closingBalanceArray1);
      principalArraySetData(principalArray1);
      dateRangeArraySetData(dateRangeArray1);
    };
    fetchData();
  }, [0]);

  const options = {
    chart: {
      type: 'column',
      height: 300
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      categories: dateRangeArray,
      labels: {
        enabled: true,
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'ProximanovaSemiBold'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Amount (₹)'
      }
    },
    plotOptions: {
      column: {
        pointWidth: 10,
        pointPadding: 0.1,
        borderWidth: 0
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      pointFormat: 'Amount: <b>{point.y:.1f}</b>'
    },
    series: [
      {
        name: 'Principal',
        color: '#77B079',
        data: principalArray,
        dataLabels: {
          enabled: false,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y}',
          y: 5,
          style: {
            fontSize: '10px',
            fontFamily: 'ProximanovaSemiBold'
          }
        }
      },
      {
        name: 'Closing Balance',
        color: '#70A0D6',
        data: closingBalanceArray,
        dataLabels: {
          enabled: false,
          rotation: -90,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y}',
          y: 5,
          style: {
            fontSize: '13px',
            fontFamily: 'ProximanovaSemiBold'
          }
        }
      }
    ]
  };

  return (
    auth0Client.isAuthenticated() && (
      <div className={classes.firstClass}>
        <Typography variant="h3" className={classes.PageTitle}>
          Member Details
        </Typography>

        <Typography variant="h5" className={classes.stepper}>
          <span
            className={classes.previousName}
            onClick={() => {
              goToUsersPage();
            }}>
            Member Ledger {'>'}{' '}
          </span>
          <span
            className={classes.previousName}
            onClick={() => {
              goToUsersPage();
            }}>
            Member Details {'>'}{' '}
          </span>
          <span className={classes.currentName}>Member Loan Details</span>
        </Typography>
        <div style={{ marginBottom: '5%' }}>
          <Grid container direction="row" spacing={3}>
            <Grid container item xs={12} md={6} lg={6}>
              <Card
                {...rest}
                className={clsx(classes.userDetailsRoot, className)}>
                <CardContent className={classes.content}>
                  <div className={classes.Cardheader}>
                    Member Profile Details
                  </div>
                  <Grid container justify="space-between">
                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>Member ID</div>
                      <div className={classes.userDetailsValue}>
                        {userDetails.memberID}
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>Name</div>
                      <div className={classes.userDetailsValue}>
                        {userDetails.memberName}
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>District</div>
                      <div className={classes.userDetailsValue}>
                        {userDetails.districtName}
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid1}>
                      <div className={classes.name}>Mandal</div>
                      <div className={classes.userDetailsValue}>
                        {userDetails.mandalName}
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid1}>
                      <div className={classes.name}>VO</div>
                      <div className={classes.userDetailsValue}>
                        {userDetails.voName}
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid1}>
                      <div className={classes.name}>SHG</div>
                      <div className={classes.userDetailsValue}>
                        {userDetails.shgName}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={12} md={6} lg={6}>
              <Card
                {...rest}
                className={clsx(classes.userDetailsRoot, className)}>
                <CardContent className={classes.content}>
                  <div className={classes.Cardheader}>Credit Score</div>
                  <Grid container alignItems="center" justify="center">
                    <ReactSpeedometer
                      value={userDetails.creditScore}
                      ringWidth={5}
                      needleColor="#70A0D6"
                      needleTransitionDuration={2000}
                      needleTransition="easeCircleInOut"
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3} className={classes.gd}>
            <Grid container item xs={12} md={6} lg={6}>
              <Card
                {...rest}
                className={clsx(classes.root, className, classes.marginTop20)}>
                <CardContent className={classes.content}>
                  <div className={classes.Cardheader}>Loan Details</div>
                  <Grid container justify="space-between">
                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>Loan Id</div>
                      <div className={classes.value}>{users.loanID}</div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>Total Amount</div>
                      <div className={classes.value}>{users.amount}</div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>Monthly EMI</div>
                      <div className={classes.value}>{users.emi}</div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid}>
                      <div className={classes.name}>Interest rate</div>
                      <div className={classes.value}>12.5%</div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid1}>
                      <div className={classes.name}>Loan Disbursement Date</div>
                      <div className={classes.value}>
                        {moment(users.disbursementDate).format('DD-MMM-YYYY')}
                      </div>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xl={4}
                      xs={12}
                      className={classes.grid1}>
                      <div className={classes.name}>Demand Generation Date</div>
                      <div className={classes.value}>
                        {moment(users.demandGenerationData).format(
                          'DD-MMM-YYYY'
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={12} md={6} lg={6}>
              <Card
                {...rest}
                className={clsx(classes.root, className, classes.marginTop20)}>
                <CardContent className={classes.content}>
                  <div className={classes.Cardheader}>Loan Schedule</div>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      height="100px"
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid container item xs={12} md={6} lg={6}>
              <Card
                {...rest}
                className={clsx(classes.loanStatusDiv, className)}>
                <CardContent className={classes.content}>
                  <div className={classes.Cardheader}>Loan Ledger</div>
                  <PerfectScrollbar>
                    <div className={classes.inner}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableHeader}>
                              Date
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Opening Balance
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Interest
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Principal
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Repaid Amount
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Closing Balance
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usersLD.map(user => (
                            <TableRow key={user.id}>
                              <TableCell className={classes.tableValue}>
                                {moment(user.date).format('DD-MMM-YYYY')}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.openingBalance}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.interest}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.principal}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.repaidAmount}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.closingBalance}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </PerfectScrollbar>
                </CardContent>
              </Card>
            </Grid>

            <Grid container item xs={12} md={6} lg={6}>
              <Card
                {...rest}
                className={clsx(classes.loanStatusDiv, className)}>
                <CardContent className={classes.content}>
                  <div className={classes.Cardheader}>Demand Ledger</div>
                  <PerfectScrollbar>
                    <div className={classes.inner}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableHeader}>
                              Date
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              EMI Number
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              EMI
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Due Date
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Over Due
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Advance Collection
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              To Be Paid
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usersDL.map(user => (
                            <TableRow key={user.id}>
                              <TableCell className={classes.tableValue}>
                                {moment(user.date).format('DD-MMM-YYYY')}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.emiNo}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.emi}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {moment(user.dueDate).format('DD-MMM-YYYY')}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.overDue}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.advanceCollection}
                              </TableCell>

                              <TableCell className={classes.tableValue}>
                                {user.toBePaid}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </PerfectScrollbar>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  );
};
MemberLoansDetails.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string
};
export default MemberLoansDetails;
