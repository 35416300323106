import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import auth0Client from '../../../../../../Auth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    paddingTop: '10px',
    paddingLeft: '0px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    height: '60px',
    color: '#ffffff',
    borderStyle: 'inset',
    borderBottom: '2px solid #1A2C39',
    fontSize: '18px',
    fontFamily: 'ProximanovaRegular',
    fontWeight: 500
  },
  icon: {
    color: '#ffffff',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: '#ffffff',
    fontFamily: 'ProximanovaSemiBold',
    background: '#1A2C39',
    fontSize: '18px',
    boxShadow: '0px 0px 1px 3px rgba(0,0,0,.1)',
    fontWeight: theme.typography.fontWeightBold,
    '& $icon': {
      color: '#ffffff'
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;
  const signOut = () => {
    auth0Client.signOut();
    //props.history.replace('/');
  };

  const signIn = () => {
    auth0Client.signIn();
  };
  const classes = useStyles();

  return auth0Client.isAuthenticated() ? (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}>
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
      {
        <div>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            variant="text"
            onClick={() => {
              signOut();
            }}>
            &nbsp; &nbsp; &nbsp; Logout &nbsp; <ExitToAppIcon />
          </Button>
        </div>
      }
    </List>
  ) : (
    <div>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        variant="text"
        onClick={() => {
          signIn();
        }}>
        &nbsp; &nbsp; &nbsp; Log In &nbsp; <ExitToAppIcon />
      </Button>
    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
