import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  root: {
    height: '93%',
    backgroundColor: '#CF9150',
    color: '#ffffff'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    paddingTop: '20px',
    fontFamily: 'ProximanovaSemiBold',
    color: theme.palette.primary.contrastText,
    fontSize: '18px'
  },
  amount: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: 'ProximanovaSemiBold'
  },
  currency: {
    fontSize: '16px',
    color: '#ffffff',
    fontWeight: 500
  }
}));

const Due = props => {
  const { className, collection, ...rest } = props;
  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h6">
              Loans Due
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography variant="h2" className={classes.amount}>
              <span className={classes.currency}></span>{' '}
              {
                <NumberFormat
                  value={props.amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  thousandsGroupStyle={'lakh'}
                />
              }
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Due.propTypes = {
  className: PropTypes.string
};

export default Due;
