import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  Demand,
  Balance,
  Collection,
  TotalCollected,
  DCBPie,
  DCBChart,
  NPA,
  PNPA,
  NPADetailView
} from './components';
import auth0Client from '../../Auth';
import axios from 'axios';
import { getConfiguration } from '../../config';
import Loader from 'react-loader-spinner';
axios.defaults.baseURL = getConfiguration().Url;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  heading: {
    marginBottom: '20px',
    color: theme.palette.primary.PageTitle,
    fontFamily: 'ProximanovaSemiBold'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  NPAGrid:{
    marginBottom:"70px"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [data, setData] = useState({ demand: '', collection: '', balance: '' });
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    !auth0Client.isAuthenticated() && auth0Client.signIn();
    const fetchData = async () => {
      const result = await axios.post('/dcb');
      setData(result.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return isLoading ? (
    <Loader
      className={classes.loader}
      type="ThreeDots"
      color="#00BFFF"
      height={400}
      width={100}
    />
  ) : (
    auth0Client.isAuthenticated() && (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.heading}>
          Dashboard
        </Typography>
        <Grid container spacing={5}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Demand
              demand={Object.keys(data.demand).map(key =>
                Number(data.demand[key]).toLocaleString()
              )}
              balance={Object.keys(data.balance).map(key =>
                Number(data.balance[key]).toLocaleString()
              )}
              collection={Object.keys(data.collection).map(key =>
                Number(data.collection[key]).toLocaleString()
              )}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Collection
              demand={Object.keys(data.demand).map(key =>
                Number(data.demand[key])
              )}
              collection={Object.keys(data.collection).map(key =>
                Number(data.collection[key])
              )}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Balance
              balance={Object.keys(data.balance).map(key =>
                Number(data.balance[key]).toLocaleString()
              )}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCollected
              collection={Object.keys(data.collection).map(key =>
                Number(data.collection[key]).toLocaleString()
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <DCBChart />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <DCBPie
              demand={Object.keys(data.demand).map(key =>
                Number(data.demand[key])
              )}
              collection={Object.keys(data.collection).map(key =>
                Number(data.collection[key])
              )}
              balance={Object.keys(data.balance).map(key =>
                Number(data.balance[key])
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12} className={classes.NPAGrid}>
            <NPA />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12} className={classes.NPAGrid}>
            <PNPA/>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default Dashboard;
